import React from 'react';
import Navbar from '../components/navbar/navbar'
import Footer from '../components/footer/footer';
import SEO from '../components/seo';
import { graphql } from 'gatsby';
import SliceZone from '../slices/SliceZone';

const InfoPage = ({location, data}) => {

  const infoPageData = data.prismicInfopage.data;
  const aniamtedNavbar = data.prismicInfopage.uid === 'index';

  return (
    <>
      <SEO
        title={infoPageData.meta_title}
        description={infoPageData.meta_description}
        socialImage={infoPageData.social_image.url}
        />
      <Navbar animated={aniamtedNavbar} currentPath={location.pathname} />
      <SliceZone slices={infoPageData.body}/>
      <Footer colorTheme="dark" paddingTop={48} />
    </>
  );
}


export const query = graphql`
  query InfoPageQuery($uid: String) {
    prismicInfopage(uid: {eq: $uid}) {
      uid
      data {
        meta_title
        meta_description
        social_image {
          url
        }
        body {
          ... on PrismicInfopageBodyHugebuttonhero {
            slice_type
            primary {
              button_label
              button_link {
                url
              }
              info_text {
                html
              }
              large_text
            }
          }
          ... on PrismicInfopageBodyTextwithprimarybutton {
            slice_type
            primary {
              background_color
              large_text
              button_label
              button_link {
                url
              }
              info_text {
                html
              }
            }
          }
          ... on PrismicInfopageBodyLargetitle {
            primary {
              background_color
              title
              underline
              uppercase
              align
            }
            slice_type
          }
          ... on PrismicInfopageBodyLighthousetest {
            primary {
              info_text {
                html
              }
              title
            }
            slice_type
          }
          ... on PrismicInfopageBodyLargetextwithlist {
            slice_type
            items {
              display_name
              service_page_link {
                url
              }
            }
            primary {
              large_text_with_list
            }
          }
          ... on PrismicInfopageBodyHerobanneranimation {
            slice_type
          }
          ... on PrismicInfopageBodyContactusform {
            slice_type
            primary {
              full_width
            }
          }
          ... on PrismicInfopageBodyContactbanner {
            slice_type
          }
          ... on PrismicInfopageBodyCards {
            slice_type
            primary {
              title
              card_desktop_height
              card_max_width
              card_mobile_height
            }
            items {
              card_text_content {
                html
              }
              card_title
              large_image {
                alt
                url
              }
              large_icon_width
              small_icon {
                alt
                url
              }
              small_icon_width
            }
          }
          ... on PrismicInfopageBodySkillscards {
            slice_type
            items {
              skill_1_level
              skill_1_title
              skill_2_level
              skill_2_title
              skill_3_level
              skill_3_title
              skills_card_image {
                fluid(maxWidth: 553, maxHeight: 553) {
                    ...GatsbyPrismicImageFluid
                  }
              }
              skills_card_text_content {
                html
              }
              skills_card_title
            }
            primary {
              skills_card_desktop_height
              skills_card_max_width
              skills_card_mobile_height
            }
          }
          ... on PrismicInfopageBodyHerotexticon {
            slice_type
            primary {
              image {
                fluid(maxWidth: 553, maxHeight: 553) {
                    ...GatsbyPrismicImageFluid
                  }
              }
              image_max_width
              info_text {
                html
              }
              info_text_double_columns
              large_text
              title
            }
          }
        }
      }
    }
  }
`

export default InfoPage;